export default [
   // *===============================================---*
  // *--------- JOB ---- ---------------------------------------*
  {
    path: '/apps/schedules/list',
    name: 'apps-schedules-list',
    component: () => import('@/views/apps/schedule/schedules-list/SchedulesList.vue'),
  },
  {
    path: '/apps/schedules/edit',
    name: 'apps-schedules-edit',
    component: () => import('@/views/apps/schedule/schedules-list/SchedulesListEdit.vue'),
  }, 
  // *===============================================---*
  // *--------- JOB ---- ---------------------------------------*
  {
    path: '/apps/jobs/list',
    name: 'apps-jobs-list',
    component: () => import('@/views/apps/job/jobs-list/JobsList.vue'),
  },
  {
    path: '/apps/jobs/edit/:id',
    name: 'apps-jobs-edit',
    component: () => import('@/views/apps/job/jobs-list/JobListEdit.vue'),
  }, 
  // *===============================================---*
  // *===============================================---*
  // *--------- USER ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/users/list',
    name: 'apps-users-list',
    component: () => import('@/views/apps/user/users-list/UsersList.vue'),
  }, 
  {
    path: '/apps/users/edit/:id',
    name: 'apps-users-edit',
    component: () => import('@/views/apps/user/users-list/UserListEdit.vue'),
  },
  // *===============================================---*
  // *===============================================---*
  // *--------- VEHICLE ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/vehicles/list',
    name: 'apps-vehicles-list',
    component: () => import('@/views/apps/vehicle/vehicles-list/VehiclesList.vue'),
  }, 
  {
    path: '/apps/vehicles/edit/:id',
    name: 'apps-vehicles-edit',
    component: () => import('@/views/apps/vehicle/vehicles-list/VehicleListEdit.vue'),
  },
  
  // *===============================================---*
  // *===============================================---*
  // *--------- MATERIAL ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/materials/list',
    name: 'apps-materials-list',
    component: () => import('@/views/apps/material/materials-list/MaterialsList.vue'),
  }, 
  {
    path: '/apps/materials/edit/:id',
    name: 'apps-materials-edit',
    component: () => import('@/views/apps/material/materials-list/MaterialListEdit.vue'),
  },
  
  // *===============================================---*
  // *===============================================---*
  // *--------- TOOL ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/tools/list',
    name: 'apps-tools-list',
    component: () => import('@/views/apps/tool/tools-list/ToolsList.vue'),
  }, 
  {
    path: '/apps/tools/edit/:id',
    name: 'apps-tools-edit',
    component: () => import('@/views/apps/tool/tools-list/ToolListEdit.vue'),
  },
  // *===============================================---*
  // *--------- TODO & IT'S FILTERS N TAGS ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/todo',
    name: 'apps-todo',
    component: () => import('@/views/apps/todo/Todo.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
    },
  },
  {
    path: '/apps/todo/:filter',
    name: 'apps-todo-filter',
    component: () => import('@/views/apps/todo/Todo.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
      navActiveLink: 'apps-todo',
    },
    beforeEnter(to, _, next) {
      if (['important', 'completed', 'deleted'].includes(to.params.filter)) next()
      else next({ name: 'error-404' })
    },
  },
  {
    path: '/apps/todo/tag/:tag',
    name: 'apps-todo-tag',
    component: () => import('@/views/apps/todo/Todo.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
      navActiveLink: 'apps-todo',
    },
    beforeEnter(to, _, next) {
      if (['team', 'low', 'medium', 'high', 'update'].includes(to.params.tag)) next()
      else next({ name: 'error-404' })
    },
  },
]
