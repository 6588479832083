import mock from '@/@fake-db/mock'
import { BNavItem } from 'bootstrap-vue'
// import { paginateArray, sortCompare } from '@/@fake-db/utils'

/* eslint-disable global-require */
const data = {
  tasks: [
    {
      id: 1,
      title: 'Mark job completed',
      dueDate: '2022-10-02',
      description:
        '<p>Set job to completion after payment.</p>',
      assignee: {
        fullName: 'Jacob Ramirez',
        avatar: '',
      },
      tags: [],
      isCompleted: false,
      isDeleted: false,
      isImportant: false,
    }, 
    {
      id: 13,
      title: "Invoicing",
      dueDate: '2020-12-01',
      description:
        '<p>Invoice for job completion.</p>',
      assignee: {
        fullName: 'Joshua Golden',
        avatar: '',
      },
      tags: [],
      isCompleted: false,
      isDeleted: false,
      isImportant: false,
    },
    {
      id: 14,
      title: 'Site Cleaning',
      dueDate: '2020-11-29',
      description:
        '<p>Cleaning after hacking work.</p>',
      assignee: {
        fullName: 'Paula Hammond',
        avatar: '',
      },
      tags:[],
      isCompleted: false,
      isDeleted: false,
      isImportant: true,
    },
    {
      id: 15,
      title: 'Wall Hacking',
      dueDate: '2020-11-29',
      description:
        '<p>On site hacking work.</p>',
      assignee: {
        fullName: 'Tyler Garcia',
        avatar: '',
      },
      tags: [],
      isCompleted: false,
      isDeleted: false,
      isImportant: false,
    },
    {
      id: 16,
      title: 'Setup protection',
      dueDate: '2020-12-12',
      description:
        '<p>Onsite set up floor protection</p>',
      assignee: {
        fullName: 'Darlene Shields',
        avatar: '',
      },
      tags: [],
      isCompleted: true,
      isDeleted: false,
      isImportant: false,
    },
    {
      id: 17,
      title: 'Prepare job for scheduling',
      dueDate: '2020-12-04',
      description:
        '<p>Ensure job status is set to active to be part of the scheduling.</p>',
      assignee: {
        fullName: 'Destiny Michael',
        avatar: '',
      },
      tags: [],
      isCompleted: true,
      isDeleted: false,
      isImportant: false,
    },
    {
      id: 18,
      title: 'Create Job in system',
      dueDate: '2020-11-18',
      description:
      '<p>Crete the job details base on the quoted item.</p>',
      assignee: {
        fullName: 'Danielle Anderson',
        avatar: '',
      },
      tags: [],
      isCompleted: true,
      isDeleted: false,
      isImportant: true,
    },
  ],
}
/* eslint-enable */

// ------------------------------------------------
// GET: Return Tasks
// ------------------------------------------------
mock.onGet('/apps/todo/tasks').reply(config => {
  // eslint-disable-next-line object-curly-newline
  const { q = '', filter, tag, sortBy: sortByParam = 'latest' } = config.params
  /* eslint-enable */

  // ------------------------------------------------
  // Get Sort by and Sort Direction
  // ------------------------------------------------
  let sortDesc = true

  const sortBy = (() => {
    if (sortByParam === 'title-asc') {
      sortDesc = false
      return 'title'
    }
    if (sortByParam === 'title-desc') return 'title'
    if (sortByParam === 'assignee') {
      sortDesc = false
      return 'assignee'
    }
    if (sortByParam === 'due-date') {
      sortDesc = false
      return 'dueDate'
    }
    return 'id'
  })()

  // ------------------------------------------------
  // Filtering
  // ------------------------------------------------
  const queryLowered = q.toLowerCase()

  const hasFilter = task => {
    if (filter === 'important') return task.isImportant && !task.isDeleted
    if (filter === 'completed') return task.isCompleted && !task.isDeleted
    if (filter === 'deleted') return task.isDeleted
    return !task.isDeleted
  }
  /* eslint-disable no-confusing-arrow, implicit-arrow-linebreak, arrow-body-style */
  const filteredData = data.tasks.filter(task => {
    return task.title.toLowerCase().includes(queryLowered) && hasFilter(task) && (tag ? task.tags.includes(tag) : true)
  })
  /* eslint-enable  */

  // ------------------------------------------------
  // Perform sorting
  // ------------------------------------------------
  const sortTasks = key => (a, b) => {
    let fieldA
    let fieldB

    // If sorting is by dueDate => Convert data to date
    if (key === 'dueDate') {
      fieldA = new Date(a[key])
      fieldB = new Date(b[key])
      // eslint-disable-next-line brace-style
    }

    // If sorting is by assignee => Use `fullName` of assignee
    else if (key === 'assignee') {
      fieldA = a.assignee ? a.assignee.fullName : null
      fieldB = b.assignee ? b.assignee.fullName : null
    } else {
      fieldA = a[key]
      fieldB = b[key]
    }

    let comparison = 0

    if (fieldA === fieldB) {
      comparison = 0
    } else if (fieldA === null) {
      comparison = 1
    } else if (fieldB === null) {
      comparison = -1
    } else if (fieldA > fieldB) {
      comparison = 1
    } else if (fieldA < fieldB) {
      comparison = -1
    }

    return comparison
  }

  // Sort Data
  const sortedData = filteredData.sort(sortTasks(sortBy))
  if (sortDesc) sortedData.reverse()

  return [200, sortedData]
})

// ------------------------------------------------
// POST: Add new task
// ------------------------------------------------
mock.onPost('/apps/todo/tasks').reply(config => {
  // Get event from post data
  const { task } = JSON.parse(config.data)

  const { length } = data.tasks
  let lastIndex = 0
  if (length) {
    lastIndex = data.tasks[length - 1].id
  }
  task.id = lastIndex + 1

  data.tasks.push(task)

  return [201, { task }]
})

// ------------------------------------------------
// POST: Update Task
// ------------------------------------------------
mock.onPost(/\/apps\/todo\/tasks\/\d+/).reply(config => {
  const { task: taskData } = JSON.parse(config.data)

  // Convert Id to number
  taskData.id = Number(taskData.id)

  const task = data.tasks.find(e => e.id === Number(taskData.id))
  Object.assign(task, taskData)

  return [200, { task }]
})

// ------------------------------------------------
// DELETE: Remove Task
// ------------------------------------------------
mock.onDelete(/\/apps\/todo\/tasks\/\d+/).reply(config => {
  // Get task id from URL
  let taskId = config.url.substring(config.url.lastIndexOf('/') + 1)

  // Convert Id to number
  taskId = Number(taskId)

  const task = data.tasks.find(t => t.id === taskId)
  Object.assign(task, { isDeleted: true })
  return [200]
})
